import React from "react";
import { useNavigate } from "react-router-dom";

function Team({
  players,
  imageMaxWidth = "max-xl:w-[150px]",
  imageMaxHeight = "max-xl:h-[150px]",
  rotateDegree = "rotate-90",
  teamColor = "red",
  stateMatch = "complete",
  fontSize = "text-base"
}) {
  const defaultImage =
    "https://admin.connect2play.site/static/images/bright-icon-rotate.jpg";
  const navigate = useNavigate();

  const handleImageClick = (playerName) => {
    const formattedName = encodeURIComponent(playerName); // เข้ารหัสชื่อให้เหมาะกับ URL
    navigate(`/paynow?name=${formattedName}`);
  };

  // กำหนดสีตาม teamColor
  const borderColor =
    teamColor === "red" ? "border-red-500" : "border-blue-500";
  const textColor = teamColor === "red" ? "text-red-500" : "text-blue-500";

  // กำหนดระดับความจาง
  const opacityStyle = stateMatch === "complete" ? "opacity-50" : "opacity-100";

  return (
    <div className="flex items-center">
      {players.map((player, index) => (
        <div key={index} className="flex flex-col items-center mx-2">
          <div
            className={`${imageMaxWidth} ${imageMaxHeight} ${rotateDegree} border-4 ${borderColor} rounded-lg overflow-hidden flex items-center justify-center cursor-pointer ${opacityStyle}`}
            onClick={() => handleImageClick(player.name)}
            style={{
              backgroundImage: `url(${player.image && !player.image.endsWith("None") ? player.image : defaultImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
          </div>
          <span className={`mt-2 text-center font-bold ${textColor} ${opacityStyle} ${fontSize}`}>
            {player.name}
          </span>
        </div>
      ))}
    </div>
  );
}

export default Team;
