import React, { useState, useEffect } from "react";
import { Card, Typography } from "@material-tailwind/react";
import { useLocation } from "react-router-dom"; // ใช้สำหรับดึง query parameters

import QrCode from "../assets/qrcode.jpg";
import config from "../config";

function PayNow() {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [matchups, setMatchups] = useState([]);
  const [shuttlecockCost, setShuttlecockCost] = useState(null);
  const [courtPrice, setCourtPrice] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const name = queryParams.get("name");
    if (name) {
      setSearchTerm(name); // กำหนดค่าเริ่มต้นของ searchTerm
    }
  }, [location.search]);

  const filteredMatchups = matchups.filter(
    (matchup) =>
      matchup.team1.some(
        (player) =>
          player?.name &&
          player.name.normalize("NFC") === searchTerm.normalize("NFC")
      ) ||
      matchup.team2.some(
        (player) =>
          player?.name &&
          player.name.normalize("NFC") === searchTerm.normalize("NFC")
      )
  );
  
  

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const fetchPayMatches = async () => {
    let currentDate = getCurrentDate();
    try {
      const response = await fetch(
        "https://admin.connect2play.site/pay_matches",
        // "http://localhost:9998/pay_matches",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: currentDate,
            company_id: config.company_id,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch matches");
      }
      const data = await response.json();
      setMatchups(data.all_matches);
      setCourtPrice(data.court_price);
      setShuttlecockCost(data.shuttlecock_price);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchPayMatches();
  }, []);

  const totalShuttlecockPrice = filteredMatchups.reduce(
    (total, matchup) => total + matchup.shuttlecock * shuttlecockCost,
    0
  );
  const totalPrice = Number(totalShuttlecockPrice) + Number(courtPrice);

  return (
    <div className="min-h-screen min-w-full flex flex-col items-center bg-gradient-to-b from-sky-950 to-amber-900 py-6">
      <Card className="w-3/4 h-auto p-6 bg-white shadow-md rounded-lg">
        <Typography className="mb-4 text-center">
          <h1 className="font-bold text-2xl">Match List</h1>
        </Typography>

        {/* Search bar */}
        <div className="flex justify-center mb-4 mt-6">
          <div className="w-full max-w-lg min-w-[200px]">
            <div className="relative">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value.trim())}
                className="w-full pl-3 pr-10 py-2 bg-transparent placeholder:text-slate-400 text-slate-600 text-sm border border-slate-200 rounded-md transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
                placeholder="ค้นหารายชื่อ..."
              />
            </div>
          </div>
        </div>

        {/* Match list */}
        <ul className="space-y-4 h-full overflow-y-auto mt-6">
          {filteredMatchups.length > 0 ? (
            filteredMatchups.map((matchup) => (
              <li
                key={matchup.courtNumber}
                className="p-4 border border-gray-200 rounded-lg hover:shadow-md transition-shadow"
              >
                <Typography variant="h6" className="text-gray-900">
                  <span>Match {matchup.courtNumber}</span>
                  <span className="text-sm text-gray-800">
                    {" "}
                    | {matchup.date} {matchup.startTime} m. - {matchup.endTime}{" "}
                    m.
                  </span>
                </Typography>
                <Typography
                  variant="small"
                  className="text-gray-500"
                ></Typography>
                <Typography variant="small" className="text-gray-700">
                  - Team1:{" "}
                  {matchup.team1.map((player) => player.name).join(", ")}
                  <br />- Team2:{" "}
                  {matchup.team2.map((player) => player.name).join(", ")}
                  <br />- Shuttlecock Amount: {matchup.shuttlecock}
                  <br />- Price: {matchup.shuttlecock * shuttlecockCost}
                </Typography>
              </li>
            ))
          ) : (
            <li className="text-center text-gray-500">No matches found</li>
          )}
        </ul>
      </Card>

      {/* สรุปราคา และ QR Code */}
      {searchTerm && filteredMatchups.length > 0 && (
        <div className="w-3/4 mt-6 p-4 bg-gray-50 shadow-sm rounded-lg flex items-center">
          {/* ส่วนสรุปราคา */}
          <div className="flex-1 px-4">
            <Typography variant="h5" className="mb-4">
              สรุปราคา
            </Typography>
            <Typography variant="body1" className="text-gray-700">
              ราคาลูกแบดมินตัน: {totalShuttlecockPrice} บาท
            </Typography>
            <Typography variant="body1" className="text-gray-700">
              ราคาสนามแบดมินตัน: {courtPrice} บาท
            </Typography>
            <Typography variant="h6" className="text-gray-900 mt-4">
              รวม: {totalPrice} บาท
            </Typography>
          </div>
          {/* QR Code Image */}
          <div className="flex justify-center items-center ml-4">
            <img
              src={QrCode}
              alt="QR Code"
              className="w-48 h-48 object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default PayNow;
