import React, { useState, useEffect } from "react";
import Team from "../components/Team";
import { Card, Spinner } from "@material-tailwind/react";

function Match({ matchups, formatDateThai, fetchMatchups }) {
    const [loading, setLoading] = useState(false);
    const [currentTime, setCurrentTime] = useState(new Date());
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: "2-digit", month: "short", year: "numeric" };
        return date.toLocaleDateString("en-GB", options).replace(/ /g, " ");
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 60000);

        return () => clearInterval(intervalId);
    }, []);

    const calculateWaitingTime = (date, startTime) => {
        const start = new Date(`${date} ${startTime}`);
        const diff = currentTime - start;
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const hours = Math.floor(diff / 1000 / 60 / 60);
        return `${hours}h ${minutes}m`;
    };

    const handleFetchMatchups = async () => {
        setLoading(true);
        await fetchMatchups();
        setLoading(false);
    };

    return (
        <div className="min-h-screen min-w-full flex flex-col bg-gradient-to-b from-sky-950 to-amber-900 py-6 sm:py-12 px-12 items-center">
            <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 w-full sm:w-3/4">
                <div className="col-span-12 sm:col-span-4">
                    <Card className="h-auto p-4 sm:p-6 bg-white shadow-md rounded-lg">
                        <div className="flex justify-between items-center mb-4 w-full">
                            <h1 className="text-xl sm:text-2xl font-bold text-left">Queue</h1>
                            <button
                                onClick={handleFetchMatchups}
                                className="ml-auto px-2 py-1"
                                disabled={loading}
                            >
                                {loading ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-5"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                        {matchups.length === 0 ? (
                            <p className="text-left text-gray-500">No matchups available.</p>
                        ) : (
                            matchups
                                .filter((matchup) => matchup.match_status === "waiting")
                                .map((matchup, index) => (
                                    <div key={index} className="mb-4 text-left">
                                        <div className="font-semibold text-base text-gray-600 max-lg:text-xs flex">
                                            <span className="pr-2">{index + 1 + ")"} </span>
                                            <span>
                                                {matchup.team1.map((player) => player.name).join(", ")}
                                            </span>
                                            <span className="italic px-2 text-base my-auto text-transparent bg-clip-text bg-gradient-to-r from-gray-800 to-orange-800 transition-all duration-500 max-lg:text-xs">
                                                VS
                                            </span>
                                            <span className="pr-2">
                                                {matchup.team2.map((player) => player.name).join(", ")}
                                            </span>
                                            <span className="italic pr-1 text-base my-auto text-transparent bg-clip-text bg-gradient-to-r from-green-800 to-teal-600 transition-all duration-500 max-lg:text-xs">
                                                {calculateWaitingTime(matchup.date, matchup.startTime)}
                                            </span>
                                        </div>
                                        {matchup.courtNumber < matchups.length && (
                                            <div className="border-b border-solid border-gray-200 my-1 w-full border-2" />
                                        )}
                                    </div>
                                ))
                        )}
                    </Card>
                </div>

                <div className="col-span-12 sm:col-span-8">
                    <Card className="h-auto p-4 sm:p-6 bg-white shadow-md rounded-lg">
                        <div className="flex justify-between items-center mb-2 w-full">
                            <h1 className="text-xl italic pl-2 pt-2 sm:text-2xl font-bold text-center">
                                2 VS 2 Matchups
                            </h1>
                            <button
                                onClick={handleFetchMatchups}
                                className="ml-auto px-2 py-1"
                                disabled={loading}
                            >
                                {loading ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-5"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                        <div className="border-b border-solid border-gray-200 my-4 w-full border-2"></div>
                        <div className="flex flex-col w-full">
                            {matchups.length === 0 ? (
                                <p className="text-center text-gray-500">
                                    No matchups available.
                                </p>
                            ) : (
                                matchups.map((matchup) => (
                                        <div
                                            key={matchup.courtNumber}
                                            className="flex flex-col mb-4 max-xl:mb-0"
                                        >
                                            <div className="flex items-center max-xl:flex-col">
                                                <div className="mx-8 text-center max-xl:flex flex-col">
                                                    <h2 className="text-xl font-bold">
                                                        Court {matchup.courtNumber}
                                                    </h2>
                                                    <div className="text-gray-500 text-sm max-xl:flex flex-row gap-4 py-2">
                                                        <p>{formatDate(matchup.date)}</p>
                                                        <p>{matchup.startTime}</p>
                                                    </div>
                                                </div>

                                                <div className="flex">
                                                    <Team
                                                        players={matchup.team1}
                                                        teamColor="red"
                                                        imageMaxWidth="w-[130px] max-2xl:w-[100px] max-xl:w-[75px] max-lg:w-[50px]"
                                                        imageMaxHeight="h-[130px] max-2xl:h-[100px] max-xl:h-[75px] max-lg:h-[50px]"
                                                        fontSize="max-2xl:text-xl max-xl:text-base max-lg:text-xs"
                                                        imageMarginX="mx-0.5"
                                                        stateMatch={matchup.match_status}
                                                    />

                                                    <span
                                                        className="font-bold italic text-transparent bg-clip-text bg-gradient-to-r from-gray-800 to-orange-800 transition-all duration-500 my-auto mt-6 max-xl:w-full max-xl:flex max-xl:justify-center text-5xl max-2xl:text-3xl max-xl:text-2xl max-lg:text-xl px-6 max-2xl:px-0.5 max-xl:px-0.5"
                                                    >
                                                        VS
                                                    </span> 
                                                    <Team
                                                        players={matchup.team2}
                                                        teamColor="blue"
                                                        imageMaxWidth="w-[130px] max-2xl:w-[100px] max-xl:w-[75px] max-lg:w-[50px]"
                                                        imageMaxHeight="h-[130px] max-2xl:h-[100px] max-xl:h-[75px] max-lg:h-[50px]"
                                                        fontSize="max-2xl:text-xl max-xl:text-base max-lg:text-xs"
                                                        imageMarginX="mx-0.5"
                                                        stateMatch={matchup.match_status}
                                                    />

                                                </div>
                                            </div>
                                            {matchup.courtNumber < matchups.length && (
                                                <div className="border-b border-solid border-gray-200 my-4 w-full border-2" />
                                            )}
                                        </div>
                                    ))
                            )}
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Match;
